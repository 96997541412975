<style scoped>
    .modal-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 30px 20px 30px;
        border-bottom: 1px solid rgb(220, 225, 230);
    }

    .modal:not(.dialog) .modal-title > div:first-child :deep(svg) {
        background: linear-gradient(90deg, #1e73be, #00a1cf);
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 9px;
    }

    .modal.dialog .modal-title {
        padding: 15px 20px 15px 20px;
    }

    .modal-title > div.title {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: #0D3B70;
        margin-top: 10px;
    }

    .modal-title > div.subtitle {
        font-size: 14px;
        font-weight: 600;
        color: #0D3B70;
        margin-top: 10px;
    }

    ::v-slotted(form > div) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
    }

    ::v-slotted(form.form-column) {
        padding: 30px 20px 0 0px !important;
    }

    ::v-slotted(form.form-column > div) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0px 15px 20px 15px;
    }

    ::v-slotted(.modal-container form) {
        padding: 30px 15px 0 15px;
    }

    ::v-slotted(.modal-container form.form-column) {
        display: flex;
    }

    .modal.dialog .modal-container {
        padding: 30px 30px 20px 30px;
    }
</style>

<template>
    <div>
        <div class="modal-title" v-if="$slots.modalIcon || $slots.modalTitle || $slots.modalSubtitle">
            <div v-if="$slots.modalIcon">
                <slot name='modalIcon'></slot>
            </div>
            <div class="title" v-if="$slots.modalTitle">
                <slot name='modalTitle'></slot>
            </div>
            <div class="subtitle" v-if="$slots.modalSubtitle">
                <slot name='modalSubtitle'></slot>
            </div>
        </div>
        <div class="modal-container" v-if="$slots.modalContent">
            <slot name="modalContent"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalStepItem'
    }
</script>