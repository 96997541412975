<style scoped>
.table-price-request-quantity {
    margin: 20px;
}

.table-price-request-quantity td,
.table-price-request-quantity th {
    padding: 6px 20px;
    text-align: left;
}

.table-price-request-quantity td:nth-child(2),
.table-price-request-quantity td:nth-child(3) {
    text-align: center;
}

.table-price-request-quantity :deep(.inputeo) {
    margin: 0px;
}

#topbar-button-container {
    display: flex;
}

#topbar-button-container :deep(button:nth-child(1)), #topbar-button-container :deep(button:nth-child(2)) {
    margin-right: 20px;
}

.list-articles-container {
    display: flex;
    width: 100%;
}

.list-articles-container :deep(.list-with-delete-container:nth-child(2)) {
    margin-top: 18px;
}

.list-articles-container :deep(.list-with-delete) {
    max-height: unset;
}

:deep(.articles-list .inputeo) {
    margin: 0px 10px;
    width: 100px;
}

.article-detail-container {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.article-detail-container div:nth-child(1) {
    flex: 2;
}

.article-detail-container div:nth-child(2), .article-detail-container div:nth-child(3), .article-detail-container div:nth-child(4) {
    flex: 1;
}

.article-detail-container {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

:deep(.articles-list .inputeo) {
    margin: 0px 10px;
    width: 100px;
}

</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Nouvelle réception" @click="displayNewCreateReceptionModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Réceptionner commande(s)" @click="displayCreateReceptionModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>

    <Modal id="modal-create-new-reception" v-show="modalCreateNewOrderDisplayed">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'plus']" />
        </template>
        <template v-slot:modalTitle>
            <div>CRÉER UNE NOUVELLE RECEPTION</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseDatepicker
                        v-model="newReception.date"
                        label="Date de réception"
                        :displayTime="true"
                        name="date"
                        :error="formErrorsModalCreateNewReception.date?.error?.message"
                        @onChange="onFormCreateNewReceptionInputChange"
                        :required="true"
                        :displayError="displayErrorModalCreateNewReception"
                    />
                    <BaseSelect
                        v-model="newReception.affair"
                        label="Affaire"
                        name="affair"
                        api="affair/search"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :minChars="2"
                        @onChange="onAffairChange"
                    />
                    <BaseSelect
                        v-model="newReception.establishment"
                        label="Établissement"
                        :defaultOptions="establishments"
                        name="establishment"
                        :error="formErrorsModalCreateNewReception?.establishment?.error?.message"
                        @onChange="onFormCreateNewReceptionInputChange"
                        :displayError="displayErrorModalCreateNewReception"
                        :required="true"
                        :disabled="newReception.affair != null"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="newReception.shippingCosts"
                        label="Frais de port"
                        type="text"
                        validator="price"
                    />
                    <BaseInput
                        v-model="newReception.packagingCosts"
                        label="Frais d'emballage"
                        type="text"
                        validator="price"
                    />
                    <BaseInput
                        v-model="newReception.additionalInfos"
                        label="Champ libre"
                        type="text"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="newReception.supplier"
                        label="Fournisseur"
                        name="supplier"
                        api="supplier/search"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        :required="true"
                        :error="formErrorsModalCreateNewReception?.supplier?.error?.message"
                        @onChange="onFormCreateNewReceptionInputChange"
                        :displayError="displayErrorModalCreateNewReception"
                        :minChars="1"
                    >
                        <template v-slot:iconAction>
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </template>
                    </BaseSelect>
                    <BaseSelect
                        v-model="form.articleCurrent"
                        label="Ajouter un article"
                        name="searchArticle"
                        @onChange="onArticleAddToNewReception"
                        api="article/search"
                        :apiParams="{
                            establishment: newReception.establishment?.value
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                    />
                </div>
                <div>
                    <div class="list-articles-container">
                        <ListWithDelete class="articles-list" v-model="newReception.articles1" label="Liste des articles" :disabled="createOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="newReception.articles1[index].quantityOrdered"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityOrdered_1_' + index"
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateNewReception['quantityOrdered_1_' + index]?.error?.message"
                                        @onChange="onFormCreateNewReceptionInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateNewReception"
                                        :isSmall="true"
                                    />
                                    <BaseInput
                                        v-model="newReception.articles1[index].delay"
                                        type="text"
                                        label="Délai (jours)"
                                        :name="'delay_1_' + index"
                                        validator="decimal"
                                        :error="formErrorsModalCreateNewReception['delay_1_' + index]?.error?.message"
                                        @onChange="onFormCreateNewReceptionInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateNewReception"
                                        :isSmall="true"
                                    />
                                    <BaseInput
                                        v-model="newReception.articles1[index].buyPrice"
                                        type="text"
                                        label="Prix achat (HT)"
                                        :name="'buyPrice_1_' + index"
                                        validator="price"
                                        :error="formErrorsModalCreateNewReception['buyPrice_1_' + index]?.error?.message"
                                        @onChange="onFormCreateNewReceptionInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateNewReception"
                                        :isSmall="true"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                        <ListWithDelete class="articles-list" v-model="newReception.articles2" v-show="newReception.articles2.length > 0" :disabled="createOrCopyOrConsultMode === 'consult'">
                            <template v-slot:content="{ item, index }">
                                <div class="article-detail-container">
                                    <div>{{ item.label }}</div>
                                    <BaseInput
                                        v-model="newReception.articles2[index].quantityOrdered"
                                        type="text"
                                        :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                        :name="'quantityOrdered_2_' + index"
                                        validator="decimal_2"
                                        :error="formErrorsModalCreateNewReception['quantityOrdered_2_' + index]?.error?.message"
                                        @onChange="onFormCreateNewReceptionInputChange"
                                        :required="true"
                                        :displayError="displayErrorModalCreateNewReception"
                                        :isSmall="true"
                                    />
                                    <BaseInput
                                        v-model="newReception.articles2[index].delay"
                                        type="text"
                                        label="Délai (jours)"
                                        validator="decimal"
                                        :isSmall="true"
                                    />
                                    <BaseInput
                                        v-model="newReception.articles2[index].buyPrice"
                                        type="text"
                                        label="Prix achat (HT)"
                                        validator="price"
                                        :isSmall="true"
                                    />
                                </div>
                            </template>
                        </ListWithDelete>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalCreateNewReception" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalCreateNewReception" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>

    <Aggrid
        apiRoute="reception/list"
        :columnDefs="columnDefs"
        :context="context"
        :apiParams="filters"
        :frameworkComponents="frameworkComponents"
        :cellRendererFunctions="cellRendererFunctions"
        :valueFormatterFunctions="valueFormatterFunctions"
        @rowClicked="((event) => consultReception(event.id))"
        aggridHeightSubstract="100px"
    >
        <template v-slot:filter>
            <div>
                <BaseSelect 
                    v-model="affairSearch"
                    label="Affaire"
                    name="affairId"
                    fieldValue="id"
                    fieldLabel="name"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="affair/search"
                    :apiParams="{
                        withFinalStatus: true
                    }"
                    :minChars="2"
                    :maxHeight="300"
                />
            </div>
            <div>
                <BaseSelect 
                    v-model="orderSearch"
                    label="Commande"
                    name="orderId"
                    fieldValue="id"
                    fieldLabel="orderNumber"
                    @onChange="onFiltersChange($event)"
                    :searchable="true"
                    api="priceRequest/search"
                    :apiParams="{
                        priceRequestMode: 'order'   
                    }"
                    :minChars="2"
                    :maxHeight="300"
                />
            </div>
        </template>
    </Aggrid>
    <Modal v-show="displayModalCreateOrEditReception">
        <template v-slot:modalIcon>
            <font-awesome-icon
                :icon="['fas', createOrCopyOrConsultMode === 'create' ? 'plus' : createOrCopyOrConsultMode === 'consult' ? 'search' : 'edit']"
            />
        </template>
        <template v-slot:modalTitle>
            <div v-show="createOrCopyOrConsultMode === 'create'">
                CRÉER UNE RÉCEPTION 
            </div>
            <div v-show="createOrCopyOrConsultMode === 'edit'">
                MODIFIER UNE RÉCEPTION ({{`R${reception.id}`}})
            </div>
            <div v-show="createOrCopyOrConsultMode === 'consult'">
                CONSULTER UNE RÉCEPTION ({{`R${reception.id}`}})
            </div>
        </template>
        <template v-slot:modalContent>
            <form class="form-column">
                <div>
                    <BaseDatepicker
                        v-model="reception.date"
                        label="Date de réception"
                        :displayTime="true"
                        name="date"
                        :error="formErrorsModalReception?.date?.error?.message"
                        @onChange="onFormReceptionInputChange"
                        :required="true"
                        :displayError="displayErrorModalReception"
                        :disabled="createOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseSelect
                        v-model="reception.supplier"
                        label="Fournisseur"
                        api="supplier/search"
                        fieldValue="id"
                        fieldLabel="name"
                        placeholder="Rechercher"
                        :searchable="true"
                        name="supplier"
                        :error="formErrorsModalReception?.supplier?.error?.message"
                        @onChange="onFormReceptionInputChange"
                        :required="true"
                        :displayError="displayErrorModalReception"
                        :minChars="1"
                        :disabled="createOrCopyOrConsultMode === 'consult'"
                    />
                    <BaseSelect
                        v-model="form.priceRequestCurrent"
                        v-if="(reception.relatedToOrder === true || reception.relatedToOrder == null) && createOrCopyOrConsultMode !== 'consult' && (reception?.supplier?.value) "
                        label="Ajouter une commande"
                        @onChange="onPriceRequestAdd"
                        api="priceRequest/search"
                        :apiParams="{
                            orderedOnly: true,
                            supplierId: reception?.supplier?.value || -1,
                            priceRequestMode: 'order',
                        }"
                        fieldValue="id"
                        fieldLabel="orderNumber"
                        :searchable="true"
                        :minChars="2"
                        :disabled="createOrCopyOrConsultMode === 'consult' || !(reception?.supplier?.value)"
                    />
                </div>
                <div>
                    <ListWithDelete
                        v-model="reception.priceRequests"
                        v-if="reception.relatedToOrder === true || reception.relatedToOrder == null"
                        label="Liste des commandes"
                        :disabled="createOrCopyOrConsultMode === 'consult'"
                    >
                        <template v-slot:content="{ item }">
                            <div>{{ item.label }}</div>
                        </template>
                    </ListWithDelete>

                    <BaseSelect
                        v-model="form.articleCurrentEstablishmentFilter"
                        v-if="reception.relatedToOrder === false"
                        label="Etablissement"
                        :defaultOptions="establishments"
                        name="searchArticleEstablishment"
                        :display-tag="true"
                        fieldLabel="label"
                        fieldValue="id"
                    />
                    <BaseSelect
                        v-model="form.articleCurrent"
                        v-if="reception.relatedToOrder === false"
                        label="Ajouter un article"
                        @onChange="onArticleAdd"
                        api="article/search"
                        :apiParams="{
                            establishment: form.articleCurrentEstablishmentFilter?.value
                        }"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                        :minChars="2"
                        :disabled="createOrCopyOrConsultMode === 'consult'"
                    />
                    <ListWithDelete
                        v-model="reception.articles"
                        v-if="reception.relatedToOrder === false"
                        label="Liste des articles"
                        class="articles-list"
                        :disabled="createOrCopyOrConsultMode === 'consult'"
                    >
                        <template v-slot:content="{ item, index }">
                            <div class="article-detail-container">
                                <div>{{ item.label }}</div>
                                <BaseInput
                                    v-model="item.quantity"
                                    type="text"
                                    :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                    :name="'quantity_' + index"
                                    validator="decimal_2"
                                    :disabled="true"
                                    :isSmall="true"
                                />
                                <BaseInput
                                    v-model="item.buyPrice"
                                    type="text"
                                    label="Prix d'achat (HT)"
                                    :name="'buyPrice_' + index"
                                    validator="price"
                                    :disabled="true"
                                    :isSmall="true"
                                />
                            </div>
                        </template>
                    </ListWithDelete>
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton
                class="white-button"
                @click="closeModalReceptionPriceRequest"
                buttonText="Fermer"
            >
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton
                v-if="createOrCopyOrConsultMode !== 'consult'"
                class="orange-button"
                @click="submitModalReceptionPriceRequest"
                buttonText="Suivant"
                :disabled="reception.priceRequests.length === 0"
            >
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal v-show="displayModalPriceRequestReception" mode="step">
        <template v-slot:modalStep>
            <ModalStep
                ref="modalPriceRequestStep"
                step="1"
                @onExit="onModalPriceRequestExit"
                @onNextStep="onModalPriceRequestNextStep"
                @onValidate="onValidatePriceRequestsForm"
                :autoNext="false"
            >
                <template v-slot:modalStepItem>
                    <ModalStepItem
                        v-for="(
                            priceRequest, indexModal
                        ) in priceRequestsWithArticles"
                        :key="priceRequest.id"
                    >
                        <template v-slot:modalTitle>
                            Commande {{ priceRequest.number }}
                        </template>
                        <template v-slot:modalContent>
                            <form>
                                <div>
                                    
                                    <BaseSelect
                                        v-model="form.articleCurrentEstablishmentFilter"
                                        label="Etablissement"
                                        :defaultOptions="establishments"
                                        name="searchArticleEstablishment"
                                        :display-tag="true"
                                        fieldLabel="label"
                                        fieldValue="id"
                                    />
                                    <BaseSelect
                                        v-model="form.articleCurrent"
                                        label="Ajouter un article"
                                        name="searchArticle"
                                        @onChange="onArticleAdd($event, indexModal)"
                                        api="article/search"
                                        :apiParams="{
                                            onlyArticleWithoutKit: true,
                                            onlyNotStock: true,
                                            establishment: form.articleCurrentEstablishmentFilter?.value
                                        }"
                                        fieldValue="id"
                                        fieldLabel="label"
                                        :searchable="true"
                                        class="select-add-article"
                                    />
                                </div>
                                <div>
                                    <table class="table-price-request-quantity">
                                        <thead>
                                            <tr>
                                                <th>Article</th>
                                                <th>Quantité commandée</th>
                                                <th>
                                                    Quantité déjà reçue<br />(autres
                                                    réceptions)
                                                </th>
                                                <th>Quantité reçue</th>
                                                <th>Prix d'achat (€)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(article, indexArticle) in priceRequest.articles" :key="article.id">
                                                <td>{{ article.label }}</td>
                                                <td>{{ article.quantityOrdered }}</td>
                                                <td>{{ article.quantityReceived }}</td>
                                                <td>
                                                    <BaseInput
                                                        v-model="article.quantityRemaining"
                                                        type="text"
                                                        validator="decimal"
                                                        :name="'quantityRemaining_' + indexArticle"
                                                        :error="formErrorsModalPriceRequest['step' + indexModal]['quantityRemaining_' + indexArticle]?.error?.message"
                                                        @onChange="onFormPriceRequestInputChange($event, indexModal, indexArticle)"
                                                        :displayError="displayErrorModalPriceRequest"
                                                        :required="true"
                                                    />
                                                </td>
                                                <td>
                                                    <BaseInput
                                                        v-model="article.buyPrice"
                                                        type="text"
                                                        validator="price"
                                                        :name="'buyPrice_' + indexArticle"
                                                        :error="formErrorsModalPriceRequest['step' + indexModal]['buyPrice_' + indexArticle]?.error?.message"
                                                        @onChange="onFormPriceRequestInputChange($event, indexModal, indexArticle)"
                                                        :displayError="displayErrorModalPriceRequest"
                                                        :required="true"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </template>
                    </ModalStepItem>
                </template>
            </ModalStep>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
import Aggrid from "../../components/Aggrid/Aggrid";
import Modal from "../../components/App/Modal";
import ModalStep from "../../components/App/ModalStep";
import ModalStepItem from "../../components/App/ModalStepItem";
import ListWithDelete from "../../components/App/ListWithDelete.vue";
import BaseDatepicker from "../../components/Base/BaseDatepicker";
import BaseSelect from "../../components/Base/BaseSelect";
import BaseInput from "../../components/Base/BaseInput";
import BaseButton from "../../components/Base/BaseButton";
import ButtonTopbar from "../../components/Topbar/ButtonTopbar.vue";
import axios from "axios";
import {compareAsc, format, parse} from "date-fns";
import changeTypeEnum from "../../enums/changeTypeEnum";
import ListReceptionsActionButtonsRenderer from "./ListReceptionsActionButtonsRenderer.vue";
import ListTextWithIconRenderer from "../../components/Aggrid/ListTextWithIconRenderer.vue";
import Dialog from "../../components/App/Dialog";
import CustomTopbar from "@/components/Topbar/CustomTopbar";
import {createToast} from "mosha-vue-toastify";
import {formatPrice} from '../../utils/formatter'

export default {
    name: "Receptions",
    components: {
        Aggrid,
        Modal,
        ModalStep,
        ModalStepItem,
        ListWithDelete,
        BaseDatepicker,
        BaseSelect,
        BaseInput,
        BaseButton,
        ButtonTopbar,
        ListReceptionsActionButtonsRenderer,
        ListTextWithIconRenderer,
        Dialog,
        CustomTopbar
    },
    data() {
        return {
            columnDefs: [
                { 
                    field: "id",
                    headerName: `Num réception`,
                    flex: 1,
                    suppressMenu: true,
                    valueFormatterFunctionName: 'ReceiptNumberValueFormaterFunction'
                },                 { 
                    field: "invoiceNumber",
                    headerName: `Num pièce`,
                    flex: 1,
                    suppressMenu: true
                },
                { field: "date", headerName: `Date`, flex: 1, filter: 'agDateColumnFilter', sortable: true },
                { field: "supplier.name", headerName: `Fournisseur`, flex: 1 },
                {
                    headerName: `Affaires`,
                    flex: 1,
                    cellRendererFunctionName: 'AffairNumberRendererFunction',
                    customFilter: 'supplierInvoice.affair.affairNumber'
                },
                {
                    headerName: `Commandes`,
                    flex: 1,
                    cellRendererFunctionName: 'OrdersRendererFunction',
                    suppressMenu: true
                }, {
                    headerName : 'Facturé', 
                    flex: 1, 
                    cellRenderer: 'ListTextWithIconRenderer',
                    cellRendererParams: { 
                        field: 'isBilled',
                        mode: 'boolean'
                    },
                    suppressMenu: true
                }, {
                    field: 'totalHT',
                    headerName : `Total HT`,
                    flex: 1,
                    valueFormatterFunctionName: 'TotalHTValueFormatterFunction',
                    filter: 'agNumberColumnFilter'
                }, {
                    field: 'totalTTC',
                    headerName : `Total TTC`,
                    flex: 1,
                    valueFormatterFunctionName: 'TotalTTCValueFormatterFunction',
                    filter: 'agNumberColumnFilter'
                }, {
                    flex: 1,
                    cellRenderer: "ListReceptionsActionButtonsRenderer",
                    suppressMenu: true
                },
            ],
            frameworkComponents: {
                ListReceptionsActionButtonsRenderer: ListReceptionsActionButtonsRenderer,
                ListTextWithIconRenderer: ListTextWithIconRenderer
            },
            cellRendererFunctions: new Map([
                [
                    'AffairNumberRendererFunction',
                    function (param) {
                        let affairs = "";
                        for (let affair in param?.data?.affairs) {
                            if (affairs.length > 0) affairs += "<br/>";
                            affairs += `<a style="width: fit-content; color: #181d1f; margin: 3px 0; padding: 2px 15px; text-decoration: none; border-radius: 4px; background-color: rgba(0, 0, 0, 0.075)" href='/affaires?search=${param?.data?.affairs[affair].id}&tab=spindle' target="_blank">${param?.data?.affairs[affair].number}</a>`;
                        }
                        return affairs;
                    }
                ],[
                    'OrdersRendererFunction',
                    function (param) {
                        let priceRequests = "";
                        for (let priceRequest in param?.data?.priceRequests) {
                            if (priceRequests.length > 0)
                                priceRequests += ", ";
                            priceRequests +=
                                param?.data?.priceRequests[priceRequest]
                                    .orderNumber;
                        }
                        return priceRequests;
                    }
                ]
            ]),
            valueFormatterFunctions: new Map([
                [
                    'ReceiptNumberValueFormaterFunction',
                    function(params){
                        return `R${params?.data?.id}`;
                    }
                ], [
                    'TotalHTValueFormatterFunction',
                    function(params){
                        return formatPrice(params?.data?.totalHT);
                    }
                ], [
                    'TotalTTCValueFormatterFunction',
                    function(params){
                        return formatPrice(params?.data?.totalTTC);
                    }
                ]
            ]),

            context: null,
            displayModalCreateOrEditReception: false,
            displayModalPriceRequestReception: false,
            createOrCopyOrConsultMode: null,
            reception: null,
            newReception: null,
            priceRequestsWithArticles: [],
            form: {
                priceRequestCurrent: null,
                articleCurrent: null,
                articleCurrentEstablishmentFilter: null
            },
            displayErrorModalReception: false,
            displayErrorModalCreateNewReception: false,
            formErrorsModalReception: {},
            formErrorsModalCreateNewReception: {},
            displayErrorModalPriceRequest: false,
            formErrorsModalPriceRequest: {},
            actualStep: null,
            affairSearch: null,
            orderSearch: null,
            filters: {
                affairId: null,
                orderId: null
            },
            modalCreateNewOrderDisplayed: false,
            establishments: [
                { label: 'EBC', value: 'ebc' },
                { label: 'SMG', value: 'smg' }
            ]
        };
    },
    beforeMount() {
        this.context = {
            componentParent: this,
        };

        this.resetReception();
        this.resetNewReception();
    },
    methods: {
        closeModalCreateOrEditReception(doReset = true) {
            if (doReset) this.resetReception();
            this.displayModalCreateOrEditReception = false;
            this.displayErrorModalReception = false;
        },
        displayCreateReceptionModal() {
            this.createOrCopyOrConsultMode = "create";
            this.resetReception();
            this.displayModalCreateOrEditReception = true;
        },
        closeModalCreateNewReception() {
            this.modalCreateNewOrderDisplayed = false;
            this.displayErrorModalCreateNewReception = false;
        },
        displayNewCreateReceptionModal() {
            this.resetNewReception();
            this.modalCreateNewOrderDisplayed = true;
        },
        displayEditReceptionModal(receptionId) {
            axios
            .get(`reception/${receptionId}`, {
                toastError: true,
                showPreloader: true,
            })
            .then((reception) => {
                this.createOrCopyOrConsultMode = "edit";
                this.prepareEditReception(reception.data);
                this.displayModalCreateOrEditReception = true;
            })
            .catch(() => {});
        },
        resetReception() {
            this.reception = {
                id: null,
                date: format(new Date(), "dd/MM/yyyy HH:mm"),
                supplier: null,
                priceRequests: [],
            };
        },
        resetNewReception() {
            this.newReception = {
                date: format(new Date(), "dd/MM/yyyy HH:mm"),
                affair: null,
                establishment: null,
                shippingCosts: null,
                packagingCosts: null,
                additionalInfos: null,
                supplier: null,
                articles1: [],
                articles2: []
            }
        },
        onFormCreateNewReceptionInputChange(input) {
            if (input.error?.message !== null) {
                this.formErrorsModalCreateNewReception[input.name] = input;
            } else {
                delete this.formErrorsModalCreateNewReception[input.name];
            }
        },
        onFormReceptionInputChange(input) {
            if (input.error?.message !== null) {
                this.formErrorsModalReception[input.name] = input;
            } else {
                delete this.formErrorsModalReception[input.name];
            }

            if (input.changeType === changeTypeEnum.USER) {
                switch (input.name) {
                    case "supplier":
                        this.reception.priceRequests = [];

                        if (input.value) {
                            axios
                            .get(
                                `priceRequest/listOrdersBySupplier?supplierId=${input.value.value}`,
                                {
                                    toastError: true,
                                    showPreloader: true,
                                }
                            )
                            .then((result) => {
                                this.reception.priceRequests = result.data.map(
                                    (data) => {
                                        return {
                                            value: data.id,
                                            label: `${data.orderNumber} (${data.orderDate})`,
                                            date: data.rawDate
                                        };
                                    }
                                );
                            })
                            .catch(() => {});
                        }
                    break;
                }
            }
        },
        onFormPriceRequestInputChange(input, stepIndex, articleIndex) {
            let step = `step${stepIndex}`;

            if (input.error?.message !== null) {
                this.formErrorsModalPriceRequest[step][input.name] = input;
            } else {
                delete this.formErrorsModalPriceRequest[step][input.name];
            }

            if (!this.formErrorsModalPriceRequest[step][input.name] || this.formErrorsModalPriceRequest[step][input.name].error?.type === "custom") {
                if (input.name.substr(0, 18) === 'quantityRemaining_') {
                    // Si la quantité reçue saisie est supérieure à la quantité restante
                    if (!this.priceRequestsWithArticles[stepIndex].articles[articleIndex].isAddAfterOrder && parseFloat(input.value) > this.priceRequestsWithArticles[stepIndex].articles[articleIndex].maxQuantity) {
                        this.formErrorsModalPriceRequest[step][input.name] = {
                            name: "customer",
                            value: input.value,
                            error: {
                                type: "custom",
                                message: `La quantité reçue ne peut pas être supérieure à la quantité restante à recevoir.`,
                            },
                        };
                    } else {
                        delete this.formErrorsModalPriceRequest[step][input.name];
                    }
                }
            }
        },
        onPriceRequestAdd(priceRequest) {
            this.$nextTick(() => {
                this.form.priceRequestCurrent = null;
            });

            // On ajoute la commande si elle n'est pas déjà présente
            if (
                priceRequest.value != null &&
                this.reception.priceRequests.filter(
                    (element) => element.value === priceRequest.value.value
                ).length === 0
            ) {
                this.reception.priceRequests.push(priceRequest.value);
            }
        },
        closeModalReceptionPriceRequest() {
            this.closeModalCreateOrEditReception();
        },
        prepareArticlesByPriceRequest(priceRequests) {
            this.priceRequestsWithArticles = [];

            if (priceRequests && priceRequests.length > 0) {
                this.priceRequestsWithArticles = priceRequests.map(
                    (priceRequest) => {
                        return {
                            id: priceRequest.id,
                            number: priceRequest.number,
                            articles: priceRequest.articles.map((article) => {
                                return {
                                    id: article.id,
                                    articleKitId: article.articleKitId,
                                    label: article.label,
                                    quantityOrdered: article.quantityOrdered,
                                    quantityReceived:
                                        article.quantityReceived || 0,
                                    quantityRemaining:
                                        article.quantity != null
                                            ? article.quantity
                                            : article.quantityRemaining,
                                    maxQuantity: article.quantityRemaining,
                                    buyPrice: article.buyPrice,
                                    isAddAfterOrder: article.isAddAfterOrder
                                };
                            }),
                        };
                    }
                );
            }
        },
        formateNewReception(reception) {
            return {
                date: reception.date,
                affairId: reception.affair?.value,
                establishment: reception.establishment,
                shippingCosts: parseFloat(reception.shippingCosts),
                packagingCosts: parseFloat(reception.packagingCosts),
                additionalInfos: reception.additionalInfos,
                supplierId: reception.supplier?.value,
                articles: reception.articles1.concat(reception.articles2).map(article =>  {
                    return {
                        id: article.value,
                        quantity: parseInt(article.quantityOrdered),
                        delay: article.data.delay,
                        buyPrice: parseFloat(article.buyPrice)
                    }
                })
            }
        },
        submitModalCreateNewReception() {
            if (!this.checkNewReceptionForm()) return;

            axios
                .post(
                    `reception/directCreate`,
                    this.formateNewReception(
                        this.newReception
                    ),
                    {
                        toastSuccess: "Réception créée",
                        toastError: true,
                        showPreloader: true,
                    }
                )
                .then(() => {
                    this.closeModalCreateNewReception();
                    this.emitter.emit("ag-grid-reload");
                })
                .catch(() => {});
        },
        checkNewReceptionForm() {
            this.displayErrorModalCreateNewReception = true;

            if (this.newReception.articles1.length === 0 && this.newReception.articles2.length === 0) {
                createToast(`Merci d'ajouter au moins un article`, {
                    position: 'bottom-right',
                    type: 'danger',
                    hideProgressBar: true,
                    showIcon: true,
                    transition: 'slide'
                });

                return false;
            }

            return Object.keys(this.formErrorsModalCreateNewReception).length === 0;
        },
        submitModalReceptionPriceRequest() {
            let url = null;
            if (this.createOrCopyOrConsultMode === "create") {
                url = `priceRequest/listArticlesByPriceRequest?priceRequestIds=${this.reception.priceRequests
                    .map((priceRequest) => priceRequest.value)
                    .toString()}`;
            } else if (this.createOrCopyOrConsultMode === "edit") {
                url = `priceRequest/listArticlesByPriceRequestFromReception?receptionId=${
                    this.reception.id
                }&priceRequestIds=${this.reception.priceRequests
                    .map((priceRequest) => priceRequest.value)
                    .toString()}`;
            }

            const prepareOrderRecep = () => {
                this.actualStep = 0;

                // Préparation de la réception avec commande
                if (this.reception.priceRequests.length > 0) {
                    axios
                        .get(url, { toastError: true, showPreloader: true })
                        .then((result) => {
                            // On prépare notre structure d'erreur
                            this.formErrorsModalPriceRequest = {};
                            for (let index = 0; index < Object.keys(result.data).length; index++) {
                                this.formErrorsModalPriceRequest[`step${index}`] = {};
                            }

                            this.prepareArticlesByPriceRequest(result.data);
                            this.closeModalCreateOrEditReception(false);
                            this.$nextTick(() => {
                                this.displayPriceRequestModal();
                            });
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                } else {
                    if (!this.checkReceptionForm()) return;

                    // Préparation de la réception sans commande associée
                    this.prepareArticlesByPriceRequest(null);
                    this.closeModalCreateOrEditReception(false);
                    this.$nextTick(() => {
                        this.displayPriceRequestModal();
                    });
                }
            }

            let alertDateConflict = false;
            const receptionDate = parse(this.reception.date, "dd/MM/yyyy H:mm", new Date())
            this.reception.priceRequests.forEach(priceRequest => {
                alertDateConflict = alertDateConflict || (compareAsc(receptionDate, new Date(priceRequest.date)) <= 0)
            })
            if (alertDateConflict) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Date de réception',
                    message: `La date de réception est antérieure aux dates de commandes. Souhaitez-vous continuer ?`
                }).then(() => {
                    prepareOrderRecep()
                })
                .catch(() => {});
            } else {
                prepareOrderRecep()
            }
        },
        resetPriceRequest() {
            this.priceRequestsWithArticles = [];
        },
        closeModalPriceRequestReception() {
            this.resetPriceRequest();
            this.displayModalPriceRequestReception = false;
        },
        displayPriceRequestModal() {
            this.$refs.modalPriceRequestStep.initializeSteps();
            this.displayModalPriceRequestReception = true;
        },
        onModalPriceRequestExit() {
            this.closeModalPriceRequestReception();
        },
        onValidatePriceRequestsForm(stepDetail) {
            if (this.checkPriceRequestForm(stepDetail.currentStep - 1)) {
                this.displayErrorModalPriceRequest = false;
                if (this.createOrCopyOrConsultMode === "create") {
                    this.createReception();
                } else {
                    this.updateReception();
                }
            }
        },
        createReception() {
            axios
                .post(
                    `reception/create`,
                    this.formateReception(
                        this.reception,
                        this.priceRequestsWithArticles
                    ),
                    {
                        toastSuccess: "Réception créée",
                        toastError: true,
                        showPreloader: true,
                    }
                )
                .then(() => {
                    this.closeModalCreateOrEditReception();
                    this.closeModalPriceRequestReception();
                    this.emitter.emit("ag-grid-reload");
                    this.$refs.modalPriceRequestStep.exit();
                })
                .catch(() => {});
        },
        updateReception() {
            axios
                .put(
                    `reception/update`,
                    this.formateReception(
                        this.reception,
                        this.priceRequestsWithArticles
                    ),
                    {
                        toastSuccess: `Modification effectuée`,
                        toastError: true,
                        showPreloader: true,
                    }
                )
                .then(() => {
                    this.closeModalCreateOrEditReception();
                    this.closeModalPriceRequestReception();
                    this.emitter.emit("ag-grid-reload");
                    this.$refs.modalPriceRequestStep.exit();
                })
                .catch(() => {});
        },
        deleteReception(receptionId) {
            this.$refs.dialog
                .show({
                    type: "confirm",
                    title: "Confirmation",
                    message: `Êtes-vous sûr de vouloir supprimer cette réception ?`,
                })
                .then(() => {
                    axios
                        .put(`reception/delete/${receptionId}`, null, {
                            toastSuccess: `Suppression effectuée`,
                            toastError: true,
                            showPreloader: true,
                        })
                        .then(() => {
                            this.emitter.emit("ag-grid-reload");
                        })
                        .catch(() => {});
                })
                .catch(() => {});
        },
        formateReception(reception, priceRequests) {
            let receptionWithPriceRequests = {
                date: reception.date,
                supplierId: reception.supplier.value,
                priceRequests: priceRequests.map((priceRequest) => {
                    return {
                        id: priceRequest.id,
                        articles: priceRequest.articles.map((article) => {
                            return {
                                id: article.id,
                                articleKitId: article.articleKitId,
                                quantityReceived: parseFloat(article.quantityRemaining),
                                buyPrice: parseFloat(article.buyPrice)
                            };
                        }),
                    };
                }),
            };

            if (reception.id != null)
                receptionWithPriceRequests.receptionId = reception.id;

            return receptionWithPriceRequests;
        },
        checkReceptionForm() {
            this.displayErrorModalReception = true;

            return this.formErrorsModalReception == null || Object.keys(this.formErrorsModalReception).length === 0;
        },
        checkPriceRequestForm(stepIndex) {
            this.displayErrorModalPriceRequest = true;
            let step = `step${stepIndex}`;

            return (
                this.formErrorsModalPriceRequest[step] == null ||
                Object.keys(this.formErrorsModalPriceRequest[step]).length === 0
            );
        },
        onModalPriceRequestNextStep(stepDetail) {
            if (this.checkPriceRequestForm(stepDetail.currentStep - 1)) {
                this.displayErrorModalPriceRequest = false;
                this.actualStep = stepDetail.currentStep;
                this.$refs.modalPriceRequestStep.next();
            }
        },
        prepareEditReception(reception) {
            this.reception = {
                id: reception.id,
                date: reception.date,
                supplier: {
                    value: reception.supplier.id,
                    label: reception.supplier.name,
                },
                priceRequests: reception.priceRequests.map((priceRequest) => {
                    return {
                        value: priceRequest.id,
                        label: `${priceRequest.orderNumber} (${priceRequest.orderDate})`
                    };
                }),
                articles: reception.articles
            };
        },
        onArticleAddToNewReception(article) {
            this.$nextTick(() => {
                this.form.articleCurrent = null;
                this.form.articleCurrentEstablishmentFilter = null;
            });

            // On ajoute l'article s'il n'est pas déjà présent
            if (article.value != null && this.newReception.articles1.filter(element => element.value === article.value.value).length === 0 && this.newReception.articles2.filter(element => element.value === article.value.value).length === 0) {

                let articleToAdd = {
                    ...article.value,
                    quantityOrdered: 1,
                    delay: 0,
                    buyPrice: article.value.data.buyPrice,
                    unit: article.value.data.unit
                };

                if (this.newReception.articles1.length <= this.newReception.articles2.length) {
                    this.newReception.articles1.push(articleToAdd);
                } else {
                    this.newReception.articles2.push(articleToAdd);
                }
            }
        },
        onArticleAdd(article, stepIndex) {
            this.$nextTick(() => {
                this.form.articleCurrent = null;
            });

            // On ajoute l'article s'il n'est pas déjà présent
            if (article.value != null && this.priceRequestsWithArticles[stepIndex]?.articles.filter((element) => element.id === article.value.value).length === 0) {
                this.priceRequestsWithArticles[stepIndex].articles.push({
                    id: article.value.data.id,
                    articleKitId: null,
                    label: article.value.data.label,
                    quantityOrdered: 0,
                    quantityReceived: 0,
                    quantityRemaining: article.value.data.quantity != null ? article.value.data.quantity : article.value.data.quantityRemaining,
                    buyPrice: 0,
                    isAddAfterOrder: true,
                    maxQuantity: Infinity,
                });
            }
        },
        redirectToAffair() {
            this.$router.push({ path: 'affaires'});
        },
        consultReception(receptionId) {
            axios
                .get(`reception/${receptionId}`, {
                    toastError: true,
                    showPreloader: true,
                })
                .then((reception) => {
                    this.createOrCopyOrConsultMode = "consult";
                    this.reception = {
                        id: reception.data.id,
                        date: reception.data.date,
                        supplier: {
                            value: reception.data.supplier.id,
                            label: reception.data.supplier.name,
                        },
                        priceRequests: reception.data.priceRequests.map((priceRequest) => {
                            return {
                                value: priceRequest.id,
                                label: `${priceRequest.orderNumber} (${priceRequest.orderDate})`
                            };
                        }),
                        articles: reception.data.articles,
                        relatedToOrder: reception.data.relatedToOrder
                    };
                    this.displayModalCreateOrEditReception = true;
                })
                .catch(() => {});
        },
        onFiltersChange(input) {
            if (input.changeType === changeTypeEnum.USER) {
                this.filters[input.name] = input?.value?.value || null;
            }
        },
        onAffairChange(input) {
            if (input.changeType === changeTypeEnum.USER) {
                if (this.newReception.affair == null) {
                    this.newReception.establishment = null;
                } else {
                    this.newReception.establishment = this.establishments.find(establishment => establishment.value === this.newReception.affair?.data?.establishment);
                }
            }
        },
        displayReceptionId(reception){
            return `R${reception.id}`;  
        }
    },
};
</script>
