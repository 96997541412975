<style scoped>
    .modal-buttons {
        margin-top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0 15px 0;
    }

    .modal-buttons > * {
        margin: 0 10px;
    }
    
    ::v-slotted(.hide-modal-step-item) {
        display: none;
    }

    .modal-step-menu-title{
        display: flex;
        flex-direction: column;
        margin-left: 10px;
    }

    .modal-buttons.mode-menu {
        padding: 10px;
        border-top: 1px solid rgb(220, 225, 230);
        width: unset;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .modal-buttons.mode-menu > div {
        display: flex;
        flex-direction: row;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .align-items-center {
        align-items: center;
    }

    .modal-step-menu-select {
        width: 390px !important;
        margin-bottom: 0 !important;
    }

    @media (max-width: 1200px) {
        .modal-step-menu-select {
            max-width: 250px !important;
        }

        .modal-button :deep(.multiselect__single) {
            font-size: 9px !important;
        }

        .modal-button :deep(.multiselect__content-wrapper) {
            font-size: 9px !important;
        }
    }
    
    .modal-step-item-container.mode-menu {
        height: calc(100% - 84px);
        overflow-x: auto;
        overflow-x: overlay;
        scrollbar-color: rgb(120, 130, 140);
        scrollbar-width: thin;
        position: relative;
    }

    .modal-step-menu-extra-buttons {
        display: flex;
    }
</style>

<style>
    .modal-buttons.mode-menu .multiselect__single {
        font-weight: 600;
    }
</style>

<template>
    <div :class="`modal-step-item-container mode-${mode}`" ref="modalStepItemContainer">
        <slot name="modalStepItem" />
    </div>
    <div class="modal-buttons mode-menu" v-if="mode === 'menu'">
        <div class="modal-step-menu-title flex-row">
                <slot name="modalStepMenuTitle" />
            </div>
        <div class="flex-row">
            <div class="flex-row">
                <BaseButton class="white-button" @click="onExit" :buttonText="closeTextButton">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'times']" />
                    </template>
                </BaseButton>
                <BaseButton class="blue-button" @click="onSave" :buttonText="saveTextButton" v-if="displaySaveButton">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'save']" />
                    </template>
                </BaseButton>
                <BaseButton :class="validateButtonClass" @click="onValidate" :buttonText="validateTextButton">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', validateIconButton]" />
                    </template>
                </BaseButton>
                <div class="modal-step-menu-extra-buttons">
                    <slot name="modalStepMenuExtraButtons" />
                </div>
            </div>
            <div style="max-width: 440px;" class="flex-row align-items-center">
                <BaseButton v-if="currentStep > minimalStep" class="grey-button" @click="onPrevious">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    </template>
                </BaseButton>
                <BaseSelect
                    class="modal-step-menu-select"
                    v-model="activeMenuItem"
                    :defaultOptions="menu"
                    :required="true"
                    @onChange="onActiveMenuItemChange"
                    :maxHeight="300"
                    openDirection="top"
                    :isMedium="true"
                >
                </BaseSelect>
                <BaseButton v-if="currentStep < maximalStep && displayNextButton" class="grey-button" @click="onNext">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </template>
                </BaseButton>
            </div>
        </div>
        
    </div>
    <div class="modal-buttons" v-else>
        <BaseButton class="white-button" @click="onExit" :buttonText="closeTextButton">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'times']" />
            </template>
        </BaseButton>
        <BaseButton v-if="currentStep > minimalStep" class="orange-button" @click="onPrevious" buttonText="Précédent">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
            </template>
        </BaseButton>
        <BaseButton v-if="currentStep < maximalStep" class="orange-button" @click="onNext" buttonText="Suivant">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </template>
        </BaseButton>
        <BaseButton v-if="currentStep === maximalStep" class="orange-button" @click="onValidate" :buttonText="validateTextButton">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'check']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../Base/BaseButton';
    import BaseSelect from '../Base/BaseSelect';
    import anime from 'animejs';
    import $ from 'jquery';

    export default {
        name: 'ModalStep',
        components: {
            BaseButton,
            BaseSelect
        },
        emits: ['onPreviousStep', 'onNextStep', 'onExit', 'onValidate', 'onSave'],
        props: {
            step: {
                type: String,
                default: "1"
            },
            excludeSteps: {
                type: Array,
                default: []
            },
            autoExit: {
                type: Boolean,
                default: true
            },
            autoPrevious: {
                type: Boolean,
                default: true
            },
            autoNext: {
                type: Boolean,
                default: true
            },
            mode: {
                type: String,
                default: "classic"
            },
            menu: {
                type: Array,
                default: []
            },
            displaySaveButton: {
                type: Boolean,
                default: false
            },
            closeTextButton: {
                type: String,
                default: "Fermer"
            },
            validateTextButton: {
                type: String,
                default: "Valider"
            },
            validateIconButton: {
                type: String,
                default: "check"
            },
            saveTextButton: {
                type: String,
                default: "Enregistrer"
            },
            validateButtonClass: {
                type: String,
                default: "blue-button"
            }
        },
        watch: {
            currentStep: {
                handler(currentStep) {
                    this.activeMenuItem = this.menu.find((e) => e.value-1 === currentStep);
                }
            }
        },
        data() {
            return {
                currentStep: null,
                minimalStep: null,
                maximalStep: null,
                activeMenuItem: null,
                displayNextButton: true
            }
        },
        mounted() {
            this.currentStep = parseInt(this.step) - 1;

            this.initializeSteps();
        },
        methods: {
            onExit() {
                if (this.autoExit === true) {
                    this.$emit('onExit', {
                        fromLastStep: false
                    });

                    this.exit();
                } else {
                    this.$emit('onExit', {
                        fromLastStep: false
                    });
                }
            },
            onPrevious(event, step = null) {
                if (this.autoPrevious === true) {
                    this.previous();
                } else {
                    this.$emit('onPreviousStep', {
                        currentStep: this.currentStep + 1,
                        wantedStep: step ?? (this.handleExcludeSteps(this.currentStep - 1, false) + 1),
                        isFirstStep: this.currentStep === this.minimalStep
                    });
                }
            },
            onNext(event, step = null) {
                if (this.autoNext === true) {
                    this.next();
                } else {
                    this.$emit('onNextStep', {
                        currentStep: this.currentStep + 1,
                        wantedStep: step ?? (this.handleExcludeSteps(this.currentStep + 1, true) + 1),
                        isFinalStep: false
                    });
                }
            },
            onValidate() {
                this.$emit('onValidate', {
                    fromLastStep: true,
                    currentStep: this.currentStep + 1
                });
            },
            onSave() {
                this.$emit('onSave', {
                    fromLastStep: true,
                    currentStep: this.currentStep + 1
                });
            },
            handleExcludeSteps(initialIndex, increment) {
                let continueLoop = true;
                let index = initialIndex;

                while (continueLoop) {
                    if (this.excludeSteps.indexOf(index + 1) !== -1) {
                        if (increment) {
                            index++;
                        } else {
                            index--;
                        }
                    } else {
                        continueLoop = false;
                    }
                }

                return index;
            },
            initializeSteps(step) {
                if (step != null) {
                    this.currentStep = parseInt(step) - 1;
                }

                this.minimalStep = this.handleExcludeSteps(0, true);
                this.maximalStep = this.handleExcludeSteps($(this.$refs.modalStepItemContainer).children('div').length - 1, false);

                this.handleSteps();
            },
            handleSteps() {
                let currentStep = this.currentStep;

                $(this.$refs.modalStepItemContainer).children('div').each(function(index) {
                    $(this).removeClass('hide-modal-step-item');

                    if (index !== currentStep) {
                        $(this).addClass('hide-modal-step-item');
                    }
                });
            },
            exit(step = null) {
                this.currentStep = step != null ? step - 1 : parseInt(this.step) - 1;
                this.handleSteps();
            },
            previous(step = null, animate = true) {
                let that = this;
                let modal = $('.modal-content:visible');

                if(animate) {
                    anime({
                        targets: modal.get(0),
                        duration: 200,
                        easing: 'easeInSine',
                        left: [0, '100vw'],
                        complete: function() {
                            that.currentStep = that.handleExcludeSteps(step != null ? step - 1 : that.currentStep - 1, false);
                            that.handleSteps();
                            anime({
                                targets: modal.get(0),
                                duration: 200,
                                easing: 'easeInSine',
                                left: ['-100vw', 0]
                            });
                        }
                    });
                }
                else {
                    that.currentStep = that.handleExcludeSteps(step != null ? step - 1 : that.currentStep - 1, true);
                    that.handleSteps();
                }
            },
            next(step = null, animate = true) {
                let that = this;
                let modal = $('.modal-content:visible');

                if(animate) {
                    anime({
                        targets: modal.get(0),
                        duration: 200,
                        easing: 'easeInSine',
                        left: [0, '-100vw'],
                        complete: function() {
                            that.currentStep = that.handleExcludeSteps(step != null ? step - 1 : that.currentStep + 1, true);
                            if (that.currentStep > that.maximalStep) {
                                that.currentStep = that.maximalStep;
                            } else {
                                that.handleSteps();
                            }
                            anime({
                                targets: modal.get(0),
                                duration: 200,
                                easing: 'easeInSine',
                                left: ['100vw', 0]
                            });
                        }
                    });
                }
                else {
                    that.currentStep = that.handleExcludeSteps(step != null ? step - 1 : that.currentStep + 1, true);
                    if (that.currentStep > that.maximalStep) {
                        that.currentStep = that.maximalStep;
                    } else {
                        that.handleSteps();
                    }
                }
            },
            getCurrentStep() {
                return this.currentStep;
            },
            onActiveMenuItemChange(menuItem) {
                if(menuItem.value) {
                    let menuItemStep = menuItem.value.value;
                    if(menuItemStep-1 < this.currentStep) {
                        this.onPrevious(null, menuItemStep);
                    }
                    else if(menuItemStep-1 > this.currentStep) {
                        this.onNext(null, menuItemStep);
                    }
                }
            },
            showNextButton() {
                this.displayNextButton = true;
            },
            hideNextButton() {
                this.displayNextButton = false;
            }
        }
    }
</script>