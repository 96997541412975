<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton @click="onEditReception" buttonText="" title="Modifier la réception" :disabled="!this.params.data.editable">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'edit']" />
            </template>
        </BaseButton>
        <BaseButton @click="onDeleteReception" buttonText="" title="Supprimer la réception" :disabled="!this.params.data.editable">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListReceptionsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteReception() {
                this.params.context.componentParent.deleteReception(this.params.data.id);
            },
            onEditReception() {
                this.params.context.componentParent.displayEditReceptionModal(this.params.data.id);
            }
        }
    }        
</script>